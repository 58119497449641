<template>
  <div>
    <v-dialog
      v-model="imageViewerDialog"
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageViewer v-bind:storage="storage.photo" @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-start>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Event Name</span> <br />
        <span class="itemValue">
          {{ storage.name }}
          <span v-if="!storage.name"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Hive ID</span> <br />
        <span v-if="storage.colony" class="itemValue">
          {{ storage.colony.colonyidentifier }}
          <span v-if="!storage.colony.colonyidentifier"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Hive Status</span> <br />
        <span v-if="storage.colony" class="itemValue">
          {{ storage.colony.colonystatus }}
          <span v-if="!storage.colony.colonystatus">-</span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Hive Source</span> <br />
        <span v-if="storage.colony" class="itemValue">
          {{ storage.colony.source }}
          <span v-if="!storage.colony.source"> - </span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Has Queen</span> <br />
        <span v-if="storage.colony" class="itemValue">
          <span v-if="storage.colony.hasqueen">Yes</span>
          <span v-else>No</span>
        </span>
      </v-flex>
      <v-flex xs6 sm3 text-left py-2>
        <span class="itemKey">Created Date</span><br />
        <span class="itemValue">
          {{ formatDate(storage.eventdate) }}
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 py-2>
        <v-divider></v-divider>
      </v-flex>
    </v-layout>
    <!-- Feeding Data -->
    <v-layout wrap justify-center v-if="storage.name == 'Feeding'">
      <v-flex xs12 text-left>
        <span class="itemHeading"> Feeding Details </span>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex
            xs6
            sm3
            text-left
            py-2
            v-for="(value, key) in storage.feeding"
            :key="key"
            text-capitalize
          >
            <span class="itemKey">{{ key }}</span> <br />
            <span class="itemValue">
              <span v-if="value == true">
                <v-icon color="green">mdi-check</v-icon>
              </span>
              <span v-else-if="value == false">
                <v-icon color="red">mdi-close</v-icon>
              </span>
              <span v-else>
                {{ value }}
              </span>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Honey Collection -->
    <v-layout wrap justify-center v-if="storage.name == 'Honey Collection'">
      <v-flex xs12 text-left>
        <span class="itemHeading"> Honey Collection Details </span>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex
            xs6
            sm3
            text-left
            py-2
            v-for="(value, key) in storage.honeycollection"
            :key="key"
            text-capitalize
          >
            <span class="itemKey">{{ key }}</span> <br />
            <span class="itemValue">
              <span v-if="value == true">
                <v-icon color="green">mdi-check</v-icon>
              </span>
              <span v-else-if="value == false">
                <v-icon color="red">mdi-close</v-icon>
              </span>
              <span v-else>
                {{ value }}
              </span>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Queen Replacement -->
    <v-layout wrap justify-center v-if="storage.name == 'Queen Replacement'">
      <v-flex xs12 text-left>
        <span class="itemHeading"> Queen Replacement Details </span>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex
            xs6
            sm3
            text-left
            py-2
            v-for="(value, key) in storage.resetcolony"
            :key="key"
            text-capitalize
          >
            <span class="itemKey">{{ key }}</span> <br />
            <span class="itemValue">
              <span v-if="value == true">
                <v-icon color="green">mdi-check</v-icon>
              </span>
              <span v-else-if="value == false">
                <v-icon color="red">mdi-close</v-icon>
              </span>
              <span v-else>
                {{ value }}
              </span>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Reset Hive -->
    <v-layout wrap justify-center v-if="storage.name == 'Reset Colony'">
      <v-flex xs12 text-left>
        <span class="itemHeading"> Reset Hive Details </span>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex
            xs6
            sm3
            text-left
            py-2
            v-for="(value, key) in storage.resetcolony"
            :key="key"
            text-capitalize
          >
            <span class="itemKey">{{ key }}</span> <br />
            <span class="itemValue">
              <span v-if="value == true">
                <v-icon color="green">mdi-check</v-icon>
              </span>
              <span v-else-if="value == false">
                <v-icon color="red">mdi-close</v-icon>
              </span>
              <span v-else>
                {{ value }}
              </span>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Diseases -->
    <v-layout wrap justify-center v-if="storage.name == 'Diseases'">
      <v-flex xs12 text-left>
        <span class="itemHeading"> Disease Details </span>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex
            xs6
            sm3
            text-left
            py-2
            v-for="(value, key) in storage.diseasestreatment"
            :key="key"
            text-capitalize
          >
            <span class="itemKey">{{ key }}</span> <br />
            <span class="itemValue">
              <span v-if="value == true">
                <v-icon color="green">mdi-check</v-icon>
              </span>
              <span v-else-if="value == false">
                <v-icon color="red">mdi-close</v-icon>
              </span>
              <span v-else>
                {{ value }}
              </span>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Division -->
    <v-layout wrap justify-center v-if="storage.name == 'Division'">
      <v-flex xs12 text-left>
        <span class="itemHeading"> Division Details </span>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex
            xs6
            sm3
            text-left
            py-2
            v-for="(value, key) in storage.division"
            :key="key"
            text-capitalize
          >
            <span class="itemKey">{{ key }}</span> <br />
            <span class="itemValue">
              <span v-if="value == true">
                <v-icon color="green">mdi-check</v-icon>
              </span>
              <span v-else-if="value == false">
                <v-icon color="red">mdi-close</v-icon>
              </span>
              <span v-else>
                {{ value }}
              </span>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Examination -->
    <v-layout wrap justify-center v-if="storage.name == 'Examination'">
      <v-flex xs12 text-left>
        <span class="itemHeading"> Examination Details </span>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex
            xs6
            sm3
            text-left
            py-2
            v-for="(value, key) in storage.examination"
            :key="key"
            text-capitalize
          >
            <span class="itemKey">{{ key }}</span> <br />
            <span class="itemValue">
              <span v-if="value == true">
                <v-icon color="green">mdi-check</v-icon>
              </span>
              <span v-else-if="value == false">
                <v-icon color="red">mdi-close</v-icon>
              </span>
              <span v-else>
                {{ value }}
              </span>
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 style="cursor: pointer">
        <v-img
          v-if="storage.photo"
          :src="mediaURL + storage.photo"
          height="250px"
          contain
          @click="imageViewerDialog = true"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="#FDCE48"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      imageViewerDialog: false,
    };
  },
  methods: {
    winStepper(windowData) {
      this.imageViewerDialog = windowData.imageViewer;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>